import React from "react";
import './FaqPage.scss';
import Arrow2 from '../../Images/next.png'
import next from '../../Images/next.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from 'html-react-parser'
// images
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function FaqPage({ Blogs, FaqList }) {
    const location = useLocation()
    const [SpecificFaq, setstate] = useState(FaqList[location.pathname.split('_').slice(-1).pop()]);
    const navigate = useNavigate()

    const getSpecificNumber = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumber()
        }
    }
    const [getNumber, setgetNumber] = useState(getSpecificNumber());

    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    return (

        <div className="FaqPage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Ontario, CA Garage Door | {SpecificFaq.questionPath}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`Ontario Garage Door | ${SpecificFaq.questionPath}`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`Ontario Garage Door | ${SpecificFaq.questionPath}`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="articleId" content={SpecificFaq.faqId} />
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content={SpecificFaq.question} />
                <meta name="SubCategories" content={SpecificFaq.questionPath} />
                <meta name="FunnelStage" content="upper" />
                <meta name="Published" content="20240410" />
                <meta name="Modified" content="20240410" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address --> */}
                <meta name="og:url" property="og:url" content={`https://www.garagedoorontario.org/faq/${SpecificFaq.questionPath.replace(/ /g, "_") + "_" + (SpecificFaq.BlogId - 1)}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Find answers to common questions about garage door services, including installations, repairs, and maintenance. Explore our FAQ page for expert advice and insights from Ontario Garage Door." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Find answers to common questions about garage door services, including installations, repairs, and maintenance. Explore our FAQ page for expert advice and insights from Ontario Garage Door." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`Ontario Garage Door | ${SpecificFaq.questionPath}`} data-react-helmet="true" />
                <meta name="keywords" content="Garage Door, Garage Door Inspection, Garage Door Repair, Garage Door Installation, Garage Door Maintenance, Garage Door Opener Installation, Garage Door Off Track Repair, Garage Door Spring Repair, Garage Door Remote Programming, Garage Door Roller Replacement, Garage Door Weather Seals, Garage Door Services, FAQ Page" data-react-helmet="true" />
            </Helmet>
            <article>

                <div className="FaqPageMainBanner">
                    <h1>Any thing you need to know about Garage Door.</h1>
                    <img onClick={() => navigate(`/faq`)} src={Arrow2} alt="Garage Door In Ontario, CA | Garage Door Repair In Ontario, CA | Garage Door Services In Ontario, CA | Garage Door Inspection In Ontario, CA" />
                </div>

                <div className="FaqPageListBox">
                    <h1>{SpecificFaq.question}</h1>
                    {SpecificFaq ? parse(SpecificFaq.answer) : null}
                    <h3><span> {SpecificFaq.category}</span></h3>
                </div>
                <div className="FaqPageListBoxMenu">
                    <div className="FaqPageListBoxMenuBox">
                        {FaqList.find(a => a.faqId == SpecificFaq.faqId).faqId != 1 ?
                            <a href={`/faq/${FaqList.find(a => a.faqId == SpecificFaq.faqId - 1).questionPath.replace(/ /g, "_") + "_" + (FaqList.find(a => a.faqId == SpecificFaq.faqId - 1).faqId - 1)}`}>
                                <img src={next} alt="Garage Door In Ontario, CA | Garage Door Repair In Ontario, CA | Garage Door Services In Ontario, CA | Garage Door Inspection In Ontario, CA" />
                                <h3> Previous</h3>
                            </a>
                            :
                            null
                        }

                    </div>
                    <div className="FaqPageListBoxMenuBox">
                        {FaqList.find(a => a.faqId == SpecificFaq.faqId).faqId != (FaqList.length) ?
                            <a href={`/faq/${FaqList.find(a => a.faqId == SpecificFaq.faqId + 1).questionPath.replace(/ /g, "_") + "_" + (FaqList.find(a => a.faqId == SpecificFaq.faqId + 1).faqId - 1)}`}>
                                <h3>Next</h3>
                                <img src={next} alt="Garage Door In Ontario, CA | Garage Door Repair In Ontario, CA | Garage Door Services In Ontario, CA | Garage Door Inspection In Ontario, CA" />
                            </a>
                            :
                            null
                        }

                    </div>
                </div>

                <div className="FaqListReview">
                    <div className="FaqListReviewHeader">
                        <h6>Frequently asked questions</h6>
                    </div>
                    <div className="FaqListReviewList">

                        {FaqList.slice(getNumber, (getNumber + 3)).map((A, index) =>
                            <article key={index} className="FaqListBoxReview">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                </div>

                <div className="MainBanner">
                    <div className="MainBannerLeft">
                        <h1>The Garage Door Experts You've Trusted for Over 50 Years in Ontario</h1>
                        <h2>Garage Door Cleaning Near Me In Ontario, California</h2>
                        <a href="tel:657-286-2385">Call Us Now!</a>
                        <a href="/book">SCHEDULE ONLINE<img src={book} alt="Garage Door Cleaning Near Me In Ontario, California" /></a>
                    </div>
                    <div className="MainBannerRight">
                        <img src={bgnobgs} alt="Garage Door In Ontario, CA | Garage Door Repair In Ontario, CA | Garage Door Services In Ontario, CA | Garage Door Inspection In Ontario, CA" />
                        <div className="MainBannerRightBubble1"></div>
                        <div className="MainBannerRightBubble2"></div>
                    </div>
                </div>
                <div className="BlogsListReview">
                    <div className="BlogsListReviewHead">
                        <h1>Related Articles</h1>
                        <a href="/blog">View All</a>

                    </div>
                    <div className="BlogsListReviewList">
                        {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                            <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                                <article>


                                    <div className="BlogsListReviewBoxLeft">
                                        <img src={A.Photos} alt={A.BlogPath} />
                                    </div>
                                    <div className="BlogsListReviewBoxRight">
                                        <h1>{A.Title}</h1>
                                        <span>
                                            {/* <h5>{readingTime(index)} MIN READ</h5> */}
                                            <h4>{A.Date}</h4>
                                        </span>
                                    </div>
                                </article>

                            </a>
                        )}
                    </div>
                </div>
                <NavigatorPath />
            </article>

        </div >
    )
}

