const LocationReviewsData = [
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Alyssa Wong",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Travis W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Ryan Gonzales",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephanie C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Cynthia Perez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Jason Watts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Vanessa J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Hayes",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Karen Hines",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Regina Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Julie Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Douglas S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Sergio Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Cole K.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Sarah Berg",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Dr. Rebecca Barr",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sheila B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Alexandra Gonzales",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lindsay N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Chad Reyes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christine R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Barbara Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Mark Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Deborah M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Jonathan Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bradley C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "John Osborne",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Nicole Villegas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christine H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Reginald Orozco",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelly P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "David Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gina N.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Paul Hunter",
        "service_type": "Garage Door Opener",
        "tech_name": "Chelsea H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Doris Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lindsey M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Denise Bauer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel E.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Patrick Erickson",
        "service_type": "Garage Door Services",
        "tech_name": "Cindy H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Adam Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Francisco M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Adrian Hanson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Michael Boyd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samuel P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Ashley Maxwell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Lori Thornton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Brandon Romero",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Emily B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Timothy Case",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicole T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Lisa Hernandez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Linda C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Mrs. Deborah Malone MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dawn H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "April Simmons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alyssa R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Antonio White",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Amber Gardner",
        "service_type": "Garage Door Opener",
        "tech_name": "Shirley H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Craig Maldonado",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Kristen Lindsey",
        "service_type": "Garage Door Installation",
        "tech_name": "Peter W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Frederick Pugh",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Colin K.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Joshua Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Wallace",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Juan Butler",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Toni B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Alyssa Little",
        "service_type": "Garage Door Opener",
        "tech_name": "Victoria W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Maria Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Julie R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Alyssa Vaughn",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles D.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Larry Curry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan E.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Diana Frazier",
        "service_type": "Garage Door Services",
        "tech_name": "Connor B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Bryce Andrade",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Mindy A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Jason Price",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Kathleen Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Paul Livingston",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Kathleen Graham",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Jeremy Spencer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Billy W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Henry Ayers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jordan M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Christina Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeffery S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Scott Horton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brett R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Harper",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Aaron Levy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Ruth Carpenter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Curtis J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Steven Burns",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Scott C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Debra Mosley",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelsey H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Martha Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "William Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Savannah Stewart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eric W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Kelly Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Megan T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Hannah Hamilton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jane C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Cindy Porter",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Phillip H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Pamela Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Beth B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Kelly Jefferson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle Y.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Danielle Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Taylor H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Beverly Orozco",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary O.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Spencer Goodwin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Robert Bauer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Grant S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jesse S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Scott Cochran",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Kelly Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Edward Arnold",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dennis A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Tiffany Hood",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Gary Galloway",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Nicholas Johnston",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carrie B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Laura Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew Q.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Alexandria Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lance M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Stephen Cannon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Whitney G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jordan Hahn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric K.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Amber Armstrong",
        "service_type": "Garage Door Installation",
        "tech_name": "Frank P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Kimberly Howard",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Monica J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Kristy Frazier",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Maria B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Guerrero",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Wendy F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Duane Riley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Simmons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "George Stevens",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Adam Ferguson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "William Morgan",
        "service_type": "Garage Door Services",
        "tech_name": "Mathew J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Waters",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maxwell H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Brandi Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "William Salazar",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Richard Grimes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan Y.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Robert Larsen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sheila W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Warner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Mark Wiggins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Christine Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nichole D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Newman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paul B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Dana Sexton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Michael Wallace",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Alison Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Wanda R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Marissa Snyder",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cynthia C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Bryan Rivers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole E.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Vanessa Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rachel S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Michael Mccormick",
        "service_type": "Garage Door Insulation",
        "tech_name": "William C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Heidi Hodges",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ian P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brianna M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Richard Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Tasha Castillo",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Ryan Henry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heather C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "David Rasmussen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Lisa Ross",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jenny H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "James Lawson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erika J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Laura Ingram",
        "service_type": "Garage Door Services",
        "tech_name": "Sean H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Travis L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Marie Roach",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Matthew Myers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Lindsey Robbins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "George T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "George Dickerson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Vickie S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Samuel Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ross",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Albert Willis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Timothy P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Bridget Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Douglas Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Connor R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Shelly Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Kevin Kelley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Peter Parker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "George Wilkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Danielle Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Terri A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Carolyn Young",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gwendolyn H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Kurt Cameron",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nancy L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Dana Shepherd",
        "service_type": "Garage Door Repair",
        "tech_name": "Brandi C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Amy Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Karen B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Jennifer Harrington",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Suzanne W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Carolyn Harris",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathleen E.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Christie Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Claire G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Jessica Reid",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Omar B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "William Copeland",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Jenna Glass",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amber W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "James Boyd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Anne Stout",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Phillip S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Joel Best",
        "service_type": "Garage Door Installation",
        "tech_name": "Aaron C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Devin Wells",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tanya H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Susan Jackson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeff J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Allen Carter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dana R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Chad Rogers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Trevor H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Barbara Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Morgan H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Gabriel Leonard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Pamela Tucker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Leah W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Nancy Ballard",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Theresa Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Mark M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Mark Hawkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Terry Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Aaron Herman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Leslie Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Karen R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Donna Jimenez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Antonio W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Deborah Garner",
        "service_type": "Garage Door Opener",
        "tech_name": "Erica G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Cheryl Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Brianna Kelly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Nancy Davies",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gerald H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Ms. Paula Kennedy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Kathryn Perez",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Robyn Duncan",
        "service_type": "Garage Door Installation",
        "tech_name": "Pamela A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Chad Branch",
        "service_type": "Garage Door Installation",
        "tech_name": "Tammie M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "John Fisher",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "John Brennan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donald J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jessica Myers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tom M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Dean Thompson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Caleb Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Roy Drake",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jonathan B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Jonathan Orozco",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bryan W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Danny Henderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Javier L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Patricia Mclaughlin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bethany B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Luke Patel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cody R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Penny Coleman",
        "service_type": "Garage Door Repair",
        "tech_name": "Donna J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Michael Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Dustin Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Carl S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Debra Mendoza",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Joshua Macdonald",
        "service_type": "Garage Door Opener",
        "tech_name": "Logan C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Robin Hayes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Lindsay Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Christine Love",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samantha S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Justin Johnston",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Maria P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Tiffany Ponce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shirley T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Jeremy Randolph",
        "service_type": "Garage Door Installation",
        "tech_name": "Julia A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Samuel West",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Charles R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Christopher Dorsey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Julian H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Bailey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shannon M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Samuel Reid",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patrick W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Melissa Valencia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Zoe W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "William Huffman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Megan J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Jamie Mckenzie",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Randall Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael V.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Lauren Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Brandy Norman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christina P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Jerome English",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jacqueline M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Jessica Lucas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Scott J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "David Ortiz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bill L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Angela Macdonald",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tom G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Michael Nichols",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Douglas B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Brandon Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Courtney R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Joseph Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Robert Alexander",
        "service_type": "Garage Door Repair",
        "tech_name": "Nancy A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Mr. John Alvarez DVM",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Christina Martin",
        "service_type": "Garage Door Opener",
        "tech_name": "Gerald M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Tina Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "George L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Laurie Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Loretta W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Lisa Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maurice S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Michelle Walton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marcia M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Michael Grimes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Zachary O.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "David Porter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jesse R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Wayne Carr",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Carrie Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Jodi Schneider",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Olivia M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Michael Mullins",
        "service_type": "Garage Door Opener",
        "tech_name": "David C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Rachel Scott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Taylor S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Justin Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sharon G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Melissa Mata",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Andrew Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tammy G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Jordan Roberts",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Debra R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Campbell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joe W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Larry Terry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandy R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Daniel Rogers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Nathan Myers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Ethan Pratt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Zachary R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Kenneth Chaney",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Steven Livingston",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Antonio M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Gates",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carla L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Samantha Valentine",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathon W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Michele Griffin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gene C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Jason Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Jasmine C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Valerie Hall",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Fisher",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Michael Hester",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jillian Davidson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "James Webster",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Hector O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Alicia Warren",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Carl E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Andre Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Monica Weaver",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andre H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Corey Rosales",
        "service_type": "Garage Door Installation",
        "tech_name": "Heidi J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Judy Warner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carla S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Mario Kane",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Karen S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Angel Armstrong",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Willie W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Kathy Mendez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexandra D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Logan Campbell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Julie Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Angela H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Brandon Castro",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nathan E.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Nicole Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Crystal C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Francisco Sharp",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Destiny Cherry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Stephanie Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Candace C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Tracy Forbes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Aaron Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Olivia Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brad B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Kristi Waller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kelly E.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Cody King",
        "service_type": "Garage Door Services",
        "tech_name": "Gary S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Dustin Flynn",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Corey S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Corey Schultz",
        "service_type": "Garage Door Services",
        "tech_name": "Raymond T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Jean West",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sean W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Kenneth Sanders",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Mindy Blanchard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Lindsay Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stacy J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Chris Valencia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dillon P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Christina Freeman",
        "service_type": "Garage Door Opener",
        "tech_name": "Ricardo N.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Ricardo Rivera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brittany R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Tyrone Jefferson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Heather Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Michael Daniel",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Pamela Chavez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jade R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Jesus Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Carolyn Chavez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephanie C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Welch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Rebecca Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Natalie F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Logan Rhodes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Brandon Palmer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Beth L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Lynn Ortega",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Loretta Ibarra",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gabriel J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Wanda Phillips",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alex F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Bruce Sawyer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Judy Dickerson",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Benjamin Andrews",
        "service_type": "Garage Door Repair",
        "tech_name": "Shawn J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Adam Gomez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mario J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Diane Clark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Henry D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Samantha Terrell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Thomas H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Patricia Holmes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nathan Z.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Tyler Morris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Norma Sherman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Hannah Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Steve J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Ruth Blanchard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "James Everett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Phillip Nguyen PhD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Beverly O.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Randy Gillespie",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "April J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Michael Warren",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Whitney Byrd",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Manuel Krueger",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julie G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Marcus Reeves",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Crystal M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Richard Russell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brenda M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Brian Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Diana J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Joseph Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Virginia H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Melissa Patterson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Joseph Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monica T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Noah Ramos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Justin V.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Richard Walker",
        "service_type": "Garage Door Services",
        "tech_name": "Alicia N.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Danielle Medina",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erik M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Christine Huang",
        "service_type": "Garage Door Repair",
        "tech_name": "Benjamin C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Regina Gilbert",
        "service_type": "Garage Door Opener",
        "tech_name": "Walter H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Sean Lamb",
        "service_type": "Garage Door Repair",
        "tech_name": "Travis W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Jennifer Thompson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Mr. Scott Townsend",
        "service_type": "Garage Door Opener",
        "tech_name": "Gina H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Nathan Bruce",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Catherine V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Daniel Hernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Barry L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Virginia Flynn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Lydia Carrillo",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nathaniel J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Eric Reese",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Thomas M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Derek Russell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shawn B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Ricky Durham",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Heather Valentine",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Douglas Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bruce C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Bradley Galloway",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Harold Fleming",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer Y.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Laura Maxwell",
        "service_type": "Garage Door Opener",
        "tech_name": "Adam B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Shane Tucker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bernard B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Jesus Perez DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kenneth H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Richard Wallace",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Bradley Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edgar C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Chapman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Spencer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Janet Wilson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Allison F.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Brianna Wallace",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shane W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Dustin Watson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rachael K.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Ryan Burns",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bethany M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Larry Mitchell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Robert Lewis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Edward L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Kelly Ward",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Brent Roberson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "David Powell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dawn P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Laurie Scott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kenneth E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Douglas Kent",
        "service_type": "Garage Door Off Track",
        "tech_name": "Thomas G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "James Powell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Eric Knight",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katie M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Becker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patrick C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Emily Pierce",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carmen J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Wanda Andrade",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Casey C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Howard",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "John Kelley",
        "service_type": "Garage Door Services",
        "tech_name": "Bryan E.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Aaron Robinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristin M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Jonathan Ritter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Caitlin R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Natasha Bender",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sandra H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "William Petersen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Douglas J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Robert Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Sandra Roberts",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexis T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "David Weber",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sherry J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Carrie Odonnell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stanley H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Jesse Marquez",
        "service_type": "Garage Door Installation",
        "tech_name": "Deborah P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Jennifer Murray DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amber M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Todd Buchanan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kayla S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Brian Phillips",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Melanie Stuart",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Oscar G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Charles Rivera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Denise C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Monica W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Judy Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alan S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Andrea Hamilton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Molly P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Thomas Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Gerald G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Marcus Simmons",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren D.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Emily Randall",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Blake S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Karen Moreno",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Dr. Kyle Jordan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Gregg Peterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tracy T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Gerald Velazquez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Zachary W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Kim Saunders",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Denise Perry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ricky B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Robert Oliver",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Mark Martin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Leslie W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Marcus Sanders",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Brenda Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Brian Lopez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bonnie R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Joshua Chapman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Pamela G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Joseph Weaver",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hayden R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "William Arnold Jr.",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kayla J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Monique Stanley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Renee Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Benjamin Lucas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Wall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "April W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Eric Carter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "David Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Todd G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Brittany Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Kenneth Jackson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Bryan Stone",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Fred H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Carol Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Marcus R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Austin Nguyen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Richard J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Tiffany Barnett",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Richard Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lisa S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Mike Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Jason Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brittany S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Joseph Cox",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Sara White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Wanda Jensen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Tiffany Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Darlene W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Brian Weaver",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristen A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Mclaughlin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Corey Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anne M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Lawrence Stevenson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dillon C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Douglas Olson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Larry G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Amanda Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Norma J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Kristine Sandoval",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Steven Tran",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dylan B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Danielle Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Robert Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Roger G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Julia Mathis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dustin W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Sara Barker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brandon W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Jimmy Nelson Jr.",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Courtney M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Christine Clark",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alex W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Denise Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gary H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Lindsay Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Lonnie Wheeler",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Bryan V.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Heather Turner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Kevin Carroll",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deborah C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Ronald Walker",
        "service_type": "Garage Door Services",
        "tech_name": "Victor S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Eric Meyer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kelly T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Ashley Ingram",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Garrett H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Lisa Watson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Gene Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erin B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Edward Allen",
        "service_type": "Garage Door Installation",
        "tech_name": "Craig G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Stephen Esparza MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Adam K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Jacob Robinson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brent S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Roberto Wood",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeremy S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Lauren Conley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christine D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Adam Mcpherson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Deborah Evans",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rachel H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Christopher Medina",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sabrina J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Patricia Schmitt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Charles A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Eric Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robin H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Michael Rivera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Carol Lam",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Kerry Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Debra Frank",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Amy Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Kevin J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Kimberly Peterson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sandra R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Jeremy Alvarez",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Kathryn Carrillo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Timothy Mooney",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Curtis M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Brandon Wiggins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Zachary V.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Kim Rollins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christine W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Andrew Lang Jr.",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ruth R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Daniel Peters",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Vanessa Brennan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Richard Stafford",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Bill Daniels",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Mathew Collins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Andre Thomas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jerome F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Christy Simmons",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Sherri Pearson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Monica P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Edward Montes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer V.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Bonnie Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Victoria N.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Mr. Brad Figueroa",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Virginia Tran",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Casey K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Monica Daniel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patrick C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Shelby Marshall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelsey G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Henry Villegas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Savannah G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Thomas Snyder",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vickie C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Dustin Gill",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Robin Fuller",
        "service_type": "Garage Door Installation",
        "tech_name": "Tina S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Karen Alexander",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melanie C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Mary Garza",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Melissa Maldonado",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandy B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Dawn Benton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amy P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Shelby King",
        "service_type": "Garage Door Insulation",
        "tech_name": "Renee N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "John R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Kelly Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tasha S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Dustin Thompson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Alexis Flores",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Vanessa H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Kayla Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erik L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Sharon Hunter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Amy Garner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Veronica M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Debra Cole",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gary M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Rachel Espinoza",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Carolyn Barnes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Danielle K.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Anna Gaines",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tiffany L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Sheryl Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Crystal Ortega",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tracy R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Teresa Hunt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Annette L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Mrs. Julia Salas MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mario P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Melanie Harris",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anthony A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Frank Fisher",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Denise J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Parrish",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Maria B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "John Baker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rebecca F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Kimberly Fleming",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Diane K.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Brittany Fischer",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Deanna Rosario",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Yolanda M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Carolyn Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Hunter G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Brandy Phillips",
        "service_type": "Garage Door Insulation",
        "tech_name": "Randy J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Alfred Johnston",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Shawn S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Katherine Park",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura N.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Luis Gordon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Craig Moore",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Erin Carey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Derrick Green",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Karen M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Emily Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mia O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Christopher Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Deanna T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Samuel Wu",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Hailey J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Sarah Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Betty M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Paul Lyons",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Alexis Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tasha F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Rebecca Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Christopher Rowland",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Dale Solomon",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Justin H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Kimberly Estrada",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samuel T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "David Mendoza",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Tammy Griffin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Carolyn W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Dennis White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Javier D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Amanda Palmer",
        "service_type": "Garage Door Installation",
        "tech_name": "Yolanda F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Ronald Harrington",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Alex Medina",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William V.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Barbara Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christian S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Justin Norman",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Anita Larson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daniel B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Anthony Gray",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Sierra Walters",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cory R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Cynthia Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Ronald M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Patrick Klein",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Krystal Torres",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Robert Hansen",
        "service_type": "Garage Door Repair",
        "tech_name": "Susan R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Emma Mcpherson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Danny P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Alexis Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Misty Pearson",
        "service_type": "Garage Door Installation",
        "tech_name": "Hannah H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Kristen Newman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Molly Mahoney",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Marie F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "John Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Peter S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Zachary Washington",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "John Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelsey W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Sarah Hubbard",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Charles Wood",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Colleen K.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Kristie Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Latasha O.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Lisa Hunter",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christian L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Michael Mccoy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rebecca C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Brian Ford",
        "service_type": "Garage Door Repair",
        "tech_name": "Jaime W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Bianca Fischer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Frances H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Shannon Butler",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Walter Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tracy M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Timothy Gomez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Derek M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "William Cohen",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Jeremy Fleming",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Alexis Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Darren K.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Brandi Tucker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bradley J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Sarah Carpenter",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Nelson DDS",
        "service_type": "Garage Door Opener",
        "tech_name": "Monica W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Juan Reynolds",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Barbara V.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Erin Walker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelsey S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Mcconnell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Frank Mack",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kevin L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Joseph Donaldson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Derrick D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Beth Ortega",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brittany B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Lindsay Dominguez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sharon E.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "James Whitehead",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Scott Walters",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Megan Ross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "David W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Alan Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathryn J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Morgan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Brenda Reese",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marie E.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Andrea White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Mark Martin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Steven Reilly",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bryan B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Robert Nielsen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Angela Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christian A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Chad Dougherty",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Randall Lyons",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher N.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Michele Butler",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony V.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Jessica Cook",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Joyce Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jerry D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Deborah Morales",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sean F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Vincent Baker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Victoria Cannon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Edward W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Andrea Kelly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "William Vincent",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Briana W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Ronald Reynolds",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Dennis Andrews",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Gregory Pollard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Benjamin S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Caroline Harris",
        "service_type": "Garage Door Installation",
        "tech_name": "Derrick W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Melissa Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert V.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Lori Barajas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Curtis R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Lisa Hamilton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charles W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Sarah Waters",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Michael Wright",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Jose Bowman",
        "service_type": "Garage Door Services",
        "tech_name": "Justin B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Joshua Fry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Barry L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Sara Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Kristen F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Justin Larson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Michael Mccarthy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Nathan Payne",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alex M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Dustin Howard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ryan B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Harrell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Victor Boyd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Todd H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Monica Walter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Aaron K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Rachel Gutierrez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Jordan Gonzales",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Margaret C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Tina Duarte",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacey P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Denise Campos",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "George Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Steven B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kathleen Wood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alicia G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Reginald Mcguire",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sandra P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Frederick Robertson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Michael Ruiz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laurie R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jeff Arnold",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mario H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Sabrina Grant",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charles B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Jack Levy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rhonda M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Brian Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kimberly K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Jonathan Saunders",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Patrick Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Mr. Manuel Cruz MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ronald P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Angela Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Brian James",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jimmy S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Terri Ramos",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dominique S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "James Flores",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Selena H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Michelle Powell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin F.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Rachel Mitchell",
        "service_type": "Garage Door Opener",
        "tech_name": "Connor F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Jonathan Tate",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Alan Wise",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Toni F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Carlos Sanchez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Louis Bridges",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bradley M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Alexis Lane",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie O.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Lisa Davidson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Brandon Ferguson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shelby G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Michael Ryan",
        "service_type": "Garage Door Repair",
        "tech_name": "Anna C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Logan Castro",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Judith Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brianna D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Patricia Wright",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lee R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "David Wilkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Colin M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Joseph Wells",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ryan S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Brent Blackwell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lindsey W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Avila",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Donna M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Warren O.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Angela Santiago",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julie V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Martha Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "David M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Michael Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erin W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Amanda Holloway",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Ricardo Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jerry T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "George Patterson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "David Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Megan R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Brittney Morris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carlos R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Ryan Hartman",
        "service_type": "Garage Door Repair",
        "tech_name": "Toni W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Brittney Lawson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelly H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Gary Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christina G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Robert Fernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Darrell G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Amber Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julie B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Ashley Adams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patricia D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Eddie Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robin W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Melvin Shelton",
        "service_type": "Garage Door Insulation",
        "tech_name": "John O.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Bishop",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicholas W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Sheila Mercer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Veronica Watson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Garner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Don Padilla",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tiffany C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Michael Lucas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adam V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Chase Carter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lindsay G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Ralph Rice MD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patricia R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Rhonda Poole",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Sara Carter",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Katie Hill",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin V.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Stephanie Rogers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Beth R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Linda Clements",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Michelle King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Timothy Pierce",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Morgan Charles",
        "service_type": "Garage Door Services",
        "tech_name": "Tina G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Edward Lee",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Monica Frey",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Hart",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Brittany Black",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Jake Fuller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Vincent P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Melissa Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "William Nunez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Jessica Proctor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashlee B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Erica Padilla",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eduardo D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Pamela Lucas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Michelle Garrison",
        "service_type": "Garage Door Off Track",
        "tech_name": "James O.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Ronald Bowman",
        "service_type": "Garage Door Installation",
        "tech_name": "Russell H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Jason Armstrong",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Travis Rodriguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Danielle Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Heather R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Jeffery Weaver",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathan O.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Joyce Forbes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Kevin Harvey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Samuel Munoz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dylan M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Alyssa Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Wayne A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Eileen Garrett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "David Madden",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandy H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Daniel Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Tony Alvarado",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pam K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Adam Myers",
        "service_type": "Garage Door Installation",
        "tech_name": "Jon L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Bianca Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie E.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Thomas Hogan MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Paul J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Eric Pearson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrew C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Bradley Edwards",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diane D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Jesse Lambert",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Samuel K.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Jared Salas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Lindsay Stewart",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Danielle Wilkinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Carrie Skinner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Barbara Dillon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Hannah R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Ariel Valdez",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Chad Richard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brad V.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Brian Ortiz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jack F.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Michael Rush",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Cameron Munoz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melvin H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "John Martin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Patricia Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Erica Good",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Steven Hill",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jodi W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Dorothy Maxwell",
        "service_type": "Garage Door Services",
        "tech_name": "Allison S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Eric Rodgers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Joseph Gibson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Melvin Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Ricky Lawson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Shannon Williams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Karen Campbell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Danielle H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Lori Joyce",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Alexander Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexis D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Frederick Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lori F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Laura Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Lisa Perry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amy L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Kimberly Chan",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Michael Murphy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Denise James",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nancy H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Desiree Bean",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Luis M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Brenda Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Steven Clark",
        "service_type": "Garage Door Insulation",
        "tech_name": "Raymond M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Donald Wiley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Jonathan Campbell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Dr. Jack Kirk PhD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dean J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Victor Love",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Natalie H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Eric Kim",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Kathleen Bell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "April R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Heather Dixon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Albert B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Amanda Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sara W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Donald Elliott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Jonathan Powers",
        "service_type": "Garage Door Services",
        "tech_name": "Gina R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "David Schmidt",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Jill Proctor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason V.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "John Mathews",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bradley C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Loretta Wiley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Lisa Lyons",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Sarah Anthony",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Crystal E.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Frank Boyer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Aaron W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Sarah Reyes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melanie W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Tiffany Cruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly F.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Alexis Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Martha M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Dr. Adam Contreras",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Zachary M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Donald Robinson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Anthony Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacqueline D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Carmen Daniels",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Stephanie Holmes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jodi L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Marcus Kelley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "William M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Christina Mcdonald",
        "service_type": "Garage Door Services",
        "tech_name": "Jon S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Maxwell Landry",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gregory M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Ryan Aguirre",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Linda Scott",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Stephen Jenkins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alfred S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Phyllis Wolf",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Morgan W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Patrick Burgess",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Debra J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Alexis Hansen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Laura Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Meghan M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Michelle Payne",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jaime B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Mariah Shelton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brooke H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "John Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mark B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "James Gomez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Nicole Pineda",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Pamela Nolan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lynn J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Sherri Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Diane K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Ashley Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Hannah Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Greg M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Jesse Pugh",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Mrs. Chelsea Morse",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "James Mitchell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jared W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Roy Kelly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Lisa Stewart",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Brian Adams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amanda S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Brian Ayala",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donna P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Andrew Flores",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Olivia G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Sara Townsend",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrew G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Kayla Snyder",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Nicole Cochran",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Mary Garrison",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Derek G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Samantha Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Veronica H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Dana Hunt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Keith K.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Michael Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Pamela Mason",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stanley R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Harold Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Jonathan Patton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Donna L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Nathan Vasquez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tanner L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Steven Reid PhD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amanda S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Crystal Fox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hunter R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Karen Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Angela M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Jessica Hernandez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Ryan Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sandra D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Shane Thompson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Laura Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Susan K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Rachel Garza",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Schneider",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Curtis G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "David Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Daniel Hurst",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Todd Watson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ralph C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Carrie King",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Kathleen Ortiz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Tracey White",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Dennis Fitzgerald",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Leslie R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Andrew Serrano",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dennis H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Deanna Randall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alison H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Justin Wallace",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rhonda B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Clayton Scott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Clinton R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Taylor Diaz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Heather Stanley",
        "service_type": "Garage Door Opener",
        "tech_name": "Douglas W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Sheri Jacobs",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Katie P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Cynthia Brock",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "James Carter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dennis C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Steven Hart",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Phyllis Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donald Y.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Laura Barnett",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Jesse Barnes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "George B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Ellen Rodriguez PhD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marcia G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Jonathan Cherry",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Gregory Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Richard Mays",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sean P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Scott Turner PhD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Jake Gibson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Ian Scott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicole T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "James Hampton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Vincent R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Susan Berry",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Wendy S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Moon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Donna White",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Deborah B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Melissa Grant",
        "service_type": "Garage Door Services",
        "tech_name": "Chad Y.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Justin Nielsen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Leonard Pacheco",
        "service_type": "Garage Door Installation",
        "tech_name": "Timothy C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Cameron L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Brett Rivera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Shields",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jaclyn C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Angela Barker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Greg N.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Renee Henderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Madeline H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Zachary Bartlett",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Cheyenne Scott",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gregory B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Lindsay Olson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Lori Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "John Bell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melissa C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Deborah Poole",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leonard W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Anita Freeman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julie L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Mrs. Monique Ford DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Sergio Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Arthur C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Anthony Haas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kristina O.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Charles Mann",
        "service_type": "Garage Door Insulation",
        "tech_name": "Justin B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Michael Chen",
        "service_type": "Garage Door Repair",
        "tech_name": "Anna H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Edward Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "James S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Patrick Sandoval",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kim C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Debra Kelly",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Dr. Kevin Weber",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brandon H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Jason Hicks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Kelly Colon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Renee Ballard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Helen R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Katherine Greene",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dustin C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "William Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shawn D.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Betty Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Connie C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Travis Lucas",
        "service_type": "Garage Door Insulation",
        "tech_name": "William M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Rodney Jimenez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara O.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Dylan Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mariah M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Maria Berry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joshua C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Rebekah Wallace",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William E.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Danielle Gonzalez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Michael Pugh",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Madison Robinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Sydney Cooper",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Seth Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Fred Leblanc",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tammy S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Martha Ellis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kari H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Vicki Long",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Morgan D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Martin Higgins",
        "service_type": "Garage Door Services",
        "tech_name": "Kenneth W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Yvonne Allen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Julia Chase",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Laura C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "James Brooks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "David Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Gail Vasquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brittany R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Taylor Mann",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Robin Morris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Scott F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Jamie Cabrera",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Diana Green",
        "service_type": "Garage Door Services",
        "tech_name": "Pamela H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Holly Pierce",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert V.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Christina Hughes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Deborah Carson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marc N.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Wendy Erickson",
        "service_type": "Garage Door Installation",
        "tech_name": "Kirk H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jennifer Buckley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Yvonne H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Zachary H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Joseph Mccormick",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly E.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Carl Kelley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Mary Taylor",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lauren M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kelsey Chan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ellen B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Bryan Sullivan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Cynthia Curtis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lori B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Ms. Tonya Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Mike S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Derek Hayes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Julie Gardner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chad S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Derrick Kelley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Deanna H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Mark Russell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Allen S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Anthony Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Kelly Holland",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer L.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Nicholas Wells",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tammy D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Holly Haynes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Tammy Harper",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Edward B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Paul Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jimmy M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Kelly Deleon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anthony R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Matthew Spencer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tina G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Samantha Cain",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Teresa G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Sandra Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Tamara A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Kayla Fischer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Sarah Harrington",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dorothy A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Lisa Patrick",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cameron L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Omar Zamora",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Xavier Burgess",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Laura Robertson",
        "service_type": "Garage Door Installation",
        "tech_name": "Donna E.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Aaron Rodgers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amber T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Melanie Wallace",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katie M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "David Castro",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Tyler Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kelly R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Bryan Oliver",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Carol Cohen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gina W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Michaela Garrison",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carolyn C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Melanie Vasquez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Bradley Ward",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeremy P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "David Hutchinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Jimmy Stanton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tammy T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Joshua Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "William Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jamie T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Cameron Vargas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Carlos Stokes",
        "service_type": "Garage Door Installation",
        "tech_name": "Natalie H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Joshua Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Emily P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Heather Powell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emma P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Tracy Lopez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christina B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Eddie Dalton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cassandra A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Bonnie Reese",
        "service_type": "Garage Door Services",
        "tech_name": "Stephen Y.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Ashley Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kaylee J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Daniel Park",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda K.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Bridget Lawson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Nancy Franklin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angelica H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Scott Liu",
        "service_type": "Garage Door Off Track",
        "tech_name": "Megan C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer Ortiz",
        "service_type": "Garage Door Opener",
        "tech_name": "Kurt H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Reginald Powell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stacy N.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Herbert Perez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristen B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stefanie J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Vincent Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Travis H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Jennifer Rhodes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Carla H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Timothy Nguyen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Steven F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jodi Henderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Darius L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Jason Russell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexis Z.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Ricardo Lee",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Alexis Shaw",
        "service_type": "Garage Door Installation",
        "tech_name": "Katherine R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Joshua Kim",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Deborah R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Mark Holmes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Brandon Hart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stacy R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Hunter Aguilar",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer V.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "David Ellis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Corey Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Heidi G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Edward Coleman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carrie B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Donald Washington",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Meredith A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Lauren Jackson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Jessica Scott",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David Q.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Ryan Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ann A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anne C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "William Watts",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "William Gomez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kevin B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Danielle Mcguire",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Margaret Gray",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Derrick Joyce",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Brenda Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Patrick E.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Kristen Wright",
        "service_type": "Garage Door Off Track",
        "tech_name": "Damon E.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Ashley Nelson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Lance Jordan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Samuel White",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Keith W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Sarah Hampton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer Q.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Theresa Kerr",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Frances A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Terry Austin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Marc R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "William Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel V.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Lisa Aguilar",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Jesse Stephens",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jill C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Timothy Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Shannon Mccoy",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Kristine Daniels",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicholas A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "John Fields",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Douglas J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Kimberly Vasquez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cindy M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Shelby Obrien",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Justin E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Kevin Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brianna P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jose Hernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brett M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Lori Warren",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Levi Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "James Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Steven Little",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shawn M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "John Berry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anthony B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Mary Hamilton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "James Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carrie G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Michael Lewis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samantha F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Renee Sanders",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Audrey M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Travis Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Ethan Jackson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Sarah Freeman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Maria Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cynthia W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Mark Wu",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Tony King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christina V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Kristen Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Douglas S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Andrew Gregory",
        "service_type": "Garage Door Opener",
        "tech_name": "Meghan A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Maria Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kari S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Brian Ward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jacqueline G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Michael Bennett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Martin M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Rebecca Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mark A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Alexis Wiley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nancy D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Kenneth Shaw",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Rebecca Cruz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ryan W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Michael Reynolds",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "James Ward",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sean F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Samantha Clements",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rhonda B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Courtney Pratt",
        "service_type": "Garage Door Repair",
        "tech_name": "George W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Michael Hudson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Howard M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Simmons",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathryn L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Virginia Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jerry B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Jacob Jefferson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrew S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Amy Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sherri H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Kevin Lam",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tanner M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Jessica Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sarah R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Dana Fernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Melissa Murillo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Briana Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Benjamin A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Alicia Joyce",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rachel N.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Matthew Leach",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samantha F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Jacob Dudley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christy S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Isabella Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Terry Rogers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Bobby W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Shelby Guerra",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Lisa Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Cheryl Wallace",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeffrey D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Scott Lewis",
        "service_type": "Garage Door Services",
        "tech_name": "Nancy W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Sharon Cooper",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erin C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Dawn Robinson DDS",
        "service_type": "Garage Door Off Track",
        "tech_name": "April C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Kelly Bell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heather G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Christine Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charlene H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Mary Jacobs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sheryl V.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Allison Phillips",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "April G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Nicole Taylor PhD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Aaron Heath",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Caleb C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "David Browning",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anna B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Laurie Stephenson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Howard W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Karen Richards",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Amy Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Jeremy Ramirez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Suzanne White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Cassandra Leonard",
        "service_type": "Garage Door Services",
        "tech_name": "Anthony G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Mrs. Lori Miller DVM",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jerry C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "William Burnett",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel Y.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Jacob Knapp Jr.",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Chad Tyler",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Natalie F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Jason Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Wendy W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "David Edwards",
        "service_type": "Garage Door Installation",
        "tech_name": "Mark A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Joshua Martin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "John Duarte",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Joseph Wilson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Vickie Rogers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Briana A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "John Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Ann J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Gonzalez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sylvia P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Bradley Huff",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristen T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Carolyn Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Valerie J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Robert Harrison",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Zoe M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Michael Mitchell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Manuel S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Mr. Cameron Gallegos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Tiffany Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alan C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Angela White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "William Shaw",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sara R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Andrea Green",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victoria B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Bryan Scott",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Veronica Patel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Luke G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Jacob Vance",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Stacie Mcpherson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Travis P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Ronald Mahoney",
        "service_type": "Garage Door Opener",
        "tech_name": "Levi F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Charles Duncan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cheryl S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Jason Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joel A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "William Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeff G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Brendan Barnett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jill Mckenzie",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristina T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Travis Lee",
        "service_type": "Garage Door Services",
        "tech_name": "James C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Matthew Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jackie M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Lisa Carr",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Johnny Y.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "David Clements",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rhonda A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Cameron Carroll",
        "service_type": "Garage Door Insulation",
        "tech_name": "John K.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Gabriel Lawson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Peter P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Michael Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Rhonda Dixon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Michelle Nguyen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrea C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Deanna Francis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan N.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Beverly Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Victor E.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Andrew Jordan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erin J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Mary Hunt",
        "service_type": "Garage Door Installation",
        "tech_name": "Dustin H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Paul Bradley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Katie R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Duane Richard",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Jennifer Hobbs",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sue W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Tara Solis",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Karen Andrade",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Stacie Thompson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Catherine James",
        "service_type": "Garage Door Opener",
        "tech_name": "Diane M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Timothy Ramos",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephen H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Nicole Bell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Barbara A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Paul Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katherine L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Sean Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathleen S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Joshua Jacobs",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Derek C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Shannon Clark",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly E.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Dustin Osborne",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Steven S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Edward Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robyn K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Jessica Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Luke Ellis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Crystal Brandt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Veronica M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Dr. Alexandra Garner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Christina Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kelly C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Dr. Joseph Vargas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Richard C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Amy Santos",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jamie T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Ivan Cox",
        "service_type": "Garage Door Off Track",
        "tech_name": "Francisco J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "William Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Edwin B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Christina Rhodes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brianna W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Michael Kennedy",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Megan S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Janice M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Nathan Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Christina Velasquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brittany S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Jessica Gordon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Nicholas Walter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Wendy Sanchez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Danielle H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Victoria Jennings",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shannon L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Laura Mcclure",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Michael Powers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Sarah Pineda",
        "service_type": "Garage Door Repair",
        "tech_name": "Francisco W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Sabrina Kirk",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chelsey F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "David Esparza",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Chris T.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Amanda Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dillon W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Lisa Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "April Harrison",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Judith Gomez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jonathan R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Maria Ward",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Tracy Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kathy J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Richard Lane",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Chelsey King",
        "service_type": "Garage Door Opener",
        "tech_name": "Antonio H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Brandi Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samantha A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Richard Holden",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mark C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Dennis Vasquez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mark C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Adam Singh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Michael Thomas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dylan L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Michelle Fox",
        "service_type": "Garage Door Services",
        "tech_name": "Tyler M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Dr. Sherry Myers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Michael Wood",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Megan H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Andrew Long",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Morgan S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Melissa Stout",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Laura P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Jack Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erin D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Allison Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Julian Mcclain",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Allison White",
        "service_type": "Garage Door Opener",
        "tech_name": "James A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Larry Reyes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Edward P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Emily Griffin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anita L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Melissa Sparks",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Robert Wright",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michele H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Mathew Scott",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kathleen B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Kline",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Angela C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Emily Lloyd",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "James Lang",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heidi M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Christensen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Blake G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Ellen Coleman",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Jacob Roman",
        "service_type": "Garage Door Installation",
        "tech_name": "Craig H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Stephanie Wright",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Donald Murillo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ellen S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Judy Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shelley J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hickman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Lori Avila",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Catherine D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Julia Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Daniel Torres",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kathleen H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Calvin Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Maria W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "John Stewart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Allison Little",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Denise Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Mark L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Blake",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kelly Mann",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jason E.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Gonzales",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth K.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Amber Randall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Joshua Ballard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Isaiah M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Jamie Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melinda D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Anthony Blackburn",
        "service_type": "Garage Door Services",
        "tech_name": "Jose P.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Taylor Glenn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Richard K.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Jeremy Boyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marcia P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "David Chapman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Jonathan Clay",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Pamela Chavez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gerald G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Michael Howell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexandria R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Theresa Velasquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dennis P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Erin Yates",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carrie C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Abigail Hamilton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Desiree B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kara P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Jennifer Stuart",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Zachary Holloway",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Noble",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Austin Hancock",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "April C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Hannah Mcintosh",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacob S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Michelle Solis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Matthew Holt",
        "service_type": "Garage Door Services",
        "tech_name": "Harry B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Harris",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Jason Fischer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Rebecca Cooper",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dylan S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Emily Holloway",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Debbie W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Sheila Diaz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "James Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tyler F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Michael Perry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Craig Parrish",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tiffany F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "David Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samuel B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "John French",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "April H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Emily Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Susan Nguyen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gina B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Bryce Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Kevin Jackson",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Brenda Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Earl V.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Brandon Chavez PhD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Monica Barnes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jonathan H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Sydney Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Katrina B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Brandon Harrington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debbie M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Jonathan Gordon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tracey S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Beth Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Virginia Grimes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Lisa Pratt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elaine B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Fry",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kenneth W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Jon Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Daniel Silva",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Cassandra Reyes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kyle O.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Stephanie Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeanette M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Sean Howard",
        "service_type": "Garage Door Installation",
        "tech_name": "Maria W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Douglas Erickson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Teresa Sanders",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bradley O.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Turner",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Gary Morris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anthony T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Matthew Villegas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Pamela Y.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Jose Wolf",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Sheila Reynolds",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "April S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Daniel Gibson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tina H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Lisa Hanson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patricia P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Cynthia Gross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Faith P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Jeremy Gray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carolyn R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Laurie Vega",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Shawn Rich",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brianna K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "David Gates",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Yesenia Crawford",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Maureen T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Joshua Park",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Emily Brooks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Martha Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Eric Mitchell",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Erik Powell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Denise S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Edward Humphrey",
        "service_type": "Garage Door Opener",
        "tech_name": "Hannah E.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Joshua Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Michael Price",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Patrick H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Destiny Obrien",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Mackenzie Ramos",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Michelle Hoffman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Devin J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Peter King",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Scott D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Brian Martin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Justin C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Paul Harper",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sonya O.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Justin Marshall",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jimmy R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Maria Petty",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Andrew Merritt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Yolanda C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Sylvia Atkins",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Brandon Grant",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ricky W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Pierce",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Jonathan Henderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kathryn W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "John Ball",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Jacob Wilkinson MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Margaret E.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Kevin Walton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Wendy Lee",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Shawna Keller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Paula Singleton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amber G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Lisa Fuentes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gary H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Jessica Cohen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rose W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Caleb Lynch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Joanna Romero",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vanessa M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Stephanie Rogers",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ethan D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Bryan Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicholas G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Carlos Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paul M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Joshua Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dana A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Michael Lin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rachel M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Bridget Brooks",
        "service_type": "Garage Door Services",
        "tech_name": "Charles C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Jessica Nixon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Chelsea S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Richard Hartman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Casey L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Katie Rice",
        "service_type": "Garage Door Installation",
        "tech_name": "Garrett C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Michelle Duran",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley Z.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Annette Gibson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Patrick Neal",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeremy T.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Teresa Taylor",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gabriel S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Randy Fischer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dominique M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Lisa Boyle",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gary H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Matthew Greene",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Bradley French DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dean W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Rhonda Keller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Keith D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Melissa Thompson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Aaron H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Paul Harris",
        "service_type": "Garage Door Installation",
        "tech_name": "Colleen M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Michael Bryant",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Jerry Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole E.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Shannon Odom",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Leslie M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Roth",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Lori Baker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Caitlyn Archer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Emily C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Hannah Holland",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Matthew Lopez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christine R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Marcus Lucas",
        "service_type": "Garage Door Installation",
        "tech_name": "Bill S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Timothy Obrien",
        "service_type": "Garage Door Off Track",
        "tech_name": "Holly E.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Rebecca Ferrell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nancy M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Max Freeman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gloria W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Leslie Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christine S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Lindsey Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Chad G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Michael Morris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cory H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Pamela Holland",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Keith D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Katrina Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cindy S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Megan Bell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Linda P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Anthony Walsh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Justin Watkins",
        "service_type": "Garage Door Services",
        "tech_name": "Alicia R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Paul Oneill",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "John Mercer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Maria J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Aaron Solis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Patricia Gregory",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Donna Mendoza",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rachel P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Holly Khan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Tina James",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Drew Abbott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Rebecca Guerra",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Michael Ferrell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Sean Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alicia R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Chad Dominguez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Michael Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Omar J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Lisa Andrews",
        "service_type": "Garage Door Opener",
        "tech_name": "Desiree L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jerome Rivera",
        "service_type": "Garage Door Repair",
        "tech_name": "Theresa W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Stacey Adams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Robert Nichols",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Lisa Gray",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Raymond G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Andrea Carrillo",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Monica W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Natalie Ballard MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Dillon Franco",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachael L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "April Mooney",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anita W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Lindsay Garza",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Kimberly Duncan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Heather B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Allison King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Benjamin R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Stephen Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Victor Bailey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Shane Lee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kayla W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Meghan Ramirez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Curtis P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Stacy Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cheryl S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Kathy Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeffrey H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Suzanne Black",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joel C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Seth Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Janet S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Justin Henderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shelly B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Leonard Joseph",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cristian J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Paul Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Megan H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Michael Benjamin",
        "service_type": "Garage Door Services",
        "tech_name": "Susan M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Aaron Adkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "James Taylor Jr.",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alisha H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Douglas Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Brenda Strong",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katherine M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Joseph Frost",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dustin G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Phyllis Graham",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Ramirez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Edward A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Kristen Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Monica B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Ross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Madeline S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Maria Tate",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Ellis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Catherine B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Robert Barry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Joseph Swanson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Terry Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrew M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Christopher Branch",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon Y.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Jessica David",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Andrea Mcdonald",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Mary Lopez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lauren B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Jeanette Stone",
        "service_type": "Garage Door Opener",
        "tech_name": "William Y.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Travis Gardner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patricia D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Terry Hurst",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Odonnell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Adam W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Anna Murphy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paul H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Trevor Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Randy D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Greg Herring",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gabrielle T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "David Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin E.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Thomas Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Taylor B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Tara Bullock",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John E.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Alexander Pearson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Frank Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Erin Mcdowell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Austin Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Ashley Hutchinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "William W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Schultz",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Ann York",
        "service_type": "Garage Door Repair",
        "tech_name": "Crystal H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "John Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Victoria P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Brian Todd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joel L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Michelle Nelson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Justin A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Bonnie Petersen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carolyn R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Patricia George",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "April Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "John Davis MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Sean Patel",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelly S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Heather Johnston",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Catherine G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Rachael Silva",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Evan Mccullough",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laurie K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Tiffany Mcmahon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Danielle J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Kayla Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Richardson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Elaine B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Kayla Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kenneth E.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Alexander Schneider",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shane Z.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Brian Koch",
        "service_type": "Garage Door Installation",
        "tech_name": "Derrick H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Jones",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melissa S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Heather Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Diane P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Brittney Ayala PhD",
        "service_type": "Garage Door Opener",
        "tech_name": "Gregory W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Taylor Peterson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Julia W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Aaron Walton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandy L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Maria Clements MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachael S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Colleen T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Samuel Hanson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samuel S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Jason Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Whitney H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Robert Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Emily R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Randall Harrison",
        "service_type": "Garage Door Repair",
        "tech_name": "Tracey E.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Paula Perez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karina R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Tyler Moses",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gina C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Jasmine Garner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Yvette G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Ashley Page",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Lynn Mcdowell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Darren M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Frank Montoya",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Zachary C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Megan Wright",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Colleen T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Allen Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robin H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Holly Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ann R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Susan Jimenez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Thomas M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Christian Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gail C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Shaun Rivera",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Martha Allison",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kara L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Paula Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeff C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Bobby Khan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Brittany Fox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Ryan Castaneda",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Dawn Pugh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James O.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Julie Paul MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Erika Watkins",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Jennifer Jimenez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danielle W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Angel Montoya",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Trevor V.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
